import {useEffect}  from "react";
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 

const PrivacyPolicyComponent = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return (
        <div>
            <Header />
            <div id="privacy" className="max-w-2xl m-6 mx-auto my-8">
              <div className="container privacy-content">  
                <h1 className="text-orange font-bold mb-4 text-primary">Privacy Policy</h1>
                 <p className="service-para mb-4">This Privacy Policy describes how we collect, use, and disclose your personal information when you use our services.</p> 
                 <h2 className="head-font mb-2">Information We Collect</h2> <ul className="list-disc pl-4 mb-4 service-para"><li>Your name, email address, and other contact information.</li> <li>Information about your usage of our services.</li> <li>Device information, such as your IP address and browser type.</li></ul>
                 <h2 className="mb-2 head-font">How We Use Your Information</h2> <ul className="list-disc pl-4 mb-4 service-para"><li>To provide and maintain our services.</li> <li>To improve and personalize your experience.</li> <li>To communicate with you, including sending updates and promotional materials.</li></ul>
                  <h2 className="head-font mb-2">Disclosure of Your Information</h2> <p className="service-para mb-4">We may share your personal information with third parties only in the ways described in this Privacy Policy.</p> 
                  <h2 className="head-font mb-2">Security</h2> <p className="mb-4 service-para">We
                take reasonable measures to protect your personal information, but no
                method of transmission over the internet or electronic storage is 100%
                secure.</p> 
                <h2 className="head-font mb-2 ">Changes to This Privacy Policy</h2>
                 <p className="service-para mb-4 ">We may update our Privacy Policy from time to time. Any changes will be posted on this page.</p>
                  <h2 className="head-font mb-2">Contact Us</h2> 
                  <p className='service-para'>If you have any questions about this Privacy Policy, please contact us at
                <a href="mailto:privacy@receptionmonk.com" className="text-blue-500"> privacy@receptionmonk.com</a>.</p></div>
            </div>    
            <Footer />
        </div>
    );
};

export default PrivacyPolicyComponent;