import {useEffect}  from "react";
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 

const RefundComponent = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div>
            <Header />
            <div id="refund" className="max-w-2xl m-6 mx-auto py-8">
                <div className="container privacy-content">
                <h1 className="text-orange font-bold mb-4 text-primary">Refund Policy</h1> 
                <p className="service-para mb-4 ">Thank you for shopping with us. If you are not entirely satisfied with your purchase, we're here to help.</p> <h2 className="head-font mb-2">Refund Conditions</h2> <p className="service-para mb-4">To be eligible for a refund, your item must be unused and in the same
                condition that you received it. It must also be in the original
                packaging.</p> 
                <h2 className="head-font mb-2">Refund Process</h2>
                 <p className="service-para mb-4">Once we receive your returned item, we will inspect it and notify you that
                    we have received your returned item. We will immediately notify you on
                    the status of your refund after inspecting the item.</p> 
                    <h2 className="head-font mb-2">Exceptions</h2> 
                    <p className="service-para mb-4">Some items are non-refundable and non-exchangeable. These include gift cards and downloadable software products.</p> 
                    <h2 className="head-font mb-2">Contact Information</h2> 
                    <p className='service-para'>If you have any questions about our Refunds Policy, please contact us at <a href="mailto:refunds@receptionmonk.com" className="text-blue-500">refunds@receptionmonk.com</a>.</p></div>
               </div>
            <Footer />
        </div>
    );
};

export default RefundComponent;