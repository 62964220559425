import React, {Suspense, useEffect} from 'react';
// @ts-ignore
import Astrisk from "../assets/images/asterisk.svg"    
// @ts-ignore
import Ai from "../assets/images/ai.svg"
// @ts-ignore
import Notification from "../assets/images/push_notifcation.svg"
// @ts-ignore
import Choose from "../assets/images/choose.svg"    
// @ts-ignore
import Voip from "../assets/images/single_number.svg"
//  @ts-ignore


const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const Technology = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div><Suspense fallback={null}><Header/></Suspense>
        <div id="technology" className="container-fluid max-w-3xl m-6 py-8">
           <div className="container-fluid">
            <div className='technology-header mb-5 d-flex flex-column align-items-center'>    
             <h1 className="text-3xl mb-4 text-primary text-orange tech-head font-bold">Our Technologies</h1>
         <p className='service-para tech-para mb-2'>Our website consists of a range of unique features that differentiate it from traditional communication methods. It provides the feature to communicate and do a conversation digitally over the internet to authorize immediate and real-time interaction through instant calling that allows you to connect and communicate with your partner instantly and transcends geographical barriers. Let's explore how our advanced approaches help to increase the efficiency and flexibility of communication for businesses of all sizes.</p>
         </div>
           <div className="row d-flex tech-features tech-background row-adjustment ">
            <div className="col-12 col-sm-12 col-md-6 voip ">
              <div className='tech-content'>  
                <h3 className='head-font mb-3'>Asterisk Integration</h3>
                 <p className='service-para tech-inner-para'>  At the core of our service is Asterisk, the world's leading open-source software capable of transforming an ordinary PC into an IP PBX. Asterisk enables a customizable telephony solution tailored to your business needs. Asterisk ensures reliable, scalable communication management—from call queues to conference calls and system integration.</p>
                 </div> 
            </div>
            <div className="col-12 col-sm-12 col-md-6 tech-image d-flex justify-content-md-end align-items-center">
             <div className='technology-assets d-flex justify-content-sm-center justify-content-center'>  
              <img src={Astrisk} className="tech-img" alt="Astrisk" title='Asterisk: Customizable IP PBX Solution' loading="lazy"/>
              </div>  
            </div>
            </div>  
            <div className="row d-flex row-adjustment">
            <div className="col-12 col-sm-12 col-md-6 voip d-flex align-items-end">
                <div className='tech-content'>
                <h3 className='head-font mb-3'>Push Notification-Based VoIP App</h3>
                 <p className='service-para tech-inner-para'>Our VoIP app uses push notifications which is a better way to handle incoming calls on your mobile app. With push notifications, you can receive an incoming call even when your phone is locked or an app isn’t running in the background. Unlike traditional VoIP apps, our app conserves battery by using push technology to activate your device only for incoming calls.</p>
                 </div> </div>
            <div className="col-12 col-sm-12 col-md-5 tech-image px-0 justify-content-center justify-content-md-start justify-content-sm-center">
         <div className="technology-assets d-flex justify-content-sm-center justify-content-center align-items-center">   
            <img src={Notification} className="tech-img" title='Push notification based voip app' alt="Notification" loading="lazy"/>
            </div>  
            </div>
            </div>  
            <div className="row d-flex tech-features tech-background row-adjustment ">
            <div className="col-12 col-sm-12 col-md-6 voip">
              <div className='tech-content'> 
                <h3 className='head-font mb-3'> Single Number Solution through PSTN to VoIP Bridge</h3>
                 <p className='service-para tech-inner-para'>We are here to simplify your communication with our advanced PSTN to VoIP bridge solution. Explore how our technology smoothly combines traditional phone lines with VoIP and offers you a good communication experience with a single number. We offer a PSTN to VoIP bridge for a unified company phone number that is fit to be used across multiple devices and locations, ensuring seamless customer contact. Whether you are in the office or anywhere, your single number follows you and provides consistency and reliability.
</p> </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 px-0 tech-image d-flex justify-content-md-end align-items-center">
           <div className="technology-assets  d-flex justify-content-sm-center justify-content-center">
            <img src={Voip} className="tech-img" alt="technology" title='Single Number Connectivity: Bridging PSTN and VoIP' loading="lazy"/>
            </div>  
            </div>
            </div>  
            <div className="row d-flex row-adjustment">
            <div className="col-12 col-sm-12 col-md-6 voip d-flex align-items-end">
               <div className='tech-content'>
                <h3 className='head-font mb-3'>AI-Based Reception and Smart Routing</h3>
                 <p className='service-para tech-inner-para'>Our intelligent system efficiently directs incoming calls to the most appropriate transfer destination. This system prioritizes routing based on predefined rules set by system owners. Our call routing system uses caller input and data to identify them and determine the purpose of their call. It then automatically directs the call to the appropriate agent or department based on the predefined rules. If the primary target is unavailable, the system intelligently determines the best alternative based on call type and purpose, cascading through available agents until it finds the most appropriate one, in order. This ensures that your calls are handled efficiently, improving customer satisfaction and streamlining your operations.</p>
                 </div></div>
            <div className="col-12 col-sm-12 col-md-6 px-0 tech-image justify-content-center justify-content-md-start justify-content-sm-center align-items-center">
          <div className="technology-assets d-flex justify-content-sm-center  align-items-center justify-content-center"> 
            <img src={Ai} className="tech-img" alt="Ai-based-routing" title='Smart Routing with AI-Based Reception Technology' loading="lazy"/>
           </div> 
            </div>
            </div>  
            <div className="row d-flex tech-features tech-background row-adjustment ">
            <div className="col-12 col-sm-12 col-md-6 voip">
               <div className='tech-content'> 
                <h3 className='head-font mb-3'>Why Choose Us?</h3>
                 <p className='service-para tech-inner-para'>At ReceptionMonk, we are committed to delivering cutting-edge technology that enhances your business communications. This app can scale and reach large audiences effortlessly and facilitates collaboration and co-creation among individuals and teams. Whether you are a small startup or a multinational corporation, We offer customized VoIP plans that help to scale your business. We designed our VoIP app to enhance efficiency, productivity, and cost-effectiveness across all organizational levels. So grow your business confidently with our scalable VoIP solutions.</p>
          </div>  </div>
            <div className="col-12 col-sm-12 col-md-6 px-0 tech-image d-flex justify-content-md-end justify-content-sm-center align-items-center">
            <div className="technology-assets d-flex justify-content-sm-center justify-content-center">  
            <img src={Choose} className="tech-img" alt="tech_img" title='Benefits of Choosing Us' loading="lazy"/>
            </div>   
            </div>
            </div>  
             </div> 
            </div>
            <Suspense fallback={null}><Footer /></Suspense>   
        </div>
    )
};export default Technology;