import React, {Suspense, useEffect} from "react";
import Header from './Header'; 
const AboutLotty = React.lazy(() => import('./Aboutanimation')); 
const LazyLodedFooter = React.lazy(() => import('./Footer'));
const About = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <Header />
      <div className="container-fluid about-container max-w-3xl m-6 py-8">
      <div className="container about-us" >
       <div className="about_us_content">    
        <div className="row row-about d-flex align-items-center">    
           
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 about-content">          
          <div className="sub-content">
          <h1 className='text-primary about-heading pb-3'>About Us</h1>   
          <h3 className="mb-3">Oustanding solutions for your needs</h3>
        <p className="mb-4">ReceptionMonk is your one-stop destination for seamless digital services and telecommunications solutions. Transform Your Business Communication with a Robust VoIP Solution Today! It allows you to make voice calls using a broadband internet connection to connect globally and offers reliable, affordable, and innovative VoIP services tailored to meet your specific needs. We specialize in providing digital calling services through our VoIP platform. Our services cater to a diverse range of needs, from personal communication to complex business solutions. We pride ourselves on a client-centric approach. Our process begins with understanding your unique needs and objectives.</p>     
        <p>Our vision is to empower individuals and businesses alike by providing the benefits of technology to simplify everyday tasks and enlarge connectivity across the globe. With years of experience, we have a team of experts who understand the fine distinction of VoIP technology. We make sure of sustained connectivity and crystal-clear voice quality, backed by robust infrastructure and redundant systems. Whether you are looking to make crystal-clear calls, manage your communications more effectively, or integrate voice capabilities into your applications, we have got you covered. Our team specializes in developing advanced VoIP websites that integrate seamlessly with your existing infrastructure. From custom-designed interfaces to robust backend systems, we ensure that your digital calling platform is not only functional but also scalable and secure.</p>
          </div>
          </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center second-col">
     <div className="about_animation"> 
      <Suspense fallback={null}> <AboutLotty /> </Suspense>
      </div>  
        </div></div></div></div></div>  
      <Suspense fallback={null}> <LazyLodedFooter />  </Suspense></div>
  ) }; export default About;