import React, { Suspense, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const AccordingComp = React.lazy(() => import('./Accordin'));

const CustomPrevArrow = ({ onClick, disabled }: { onClick?: () => void, disabled?: boolean }) => (
    <div
        className={`slick-prev ${disabled ? 'slick-disabled' : ''}`}
        onClick={onClick}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
        <i className="bi bi-caret-left-square-fill"></i>
    </div>
);

const CustomNextArrow = ({ onClick, disabled }: { onClick?: () => void, disabled?: boolean }) => (

    <div
        className={`slick-next ${disabled ? 'slick-disabled' : ''}`}
        onClick={onClick}
        
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
        <i className="bi bi-caret-right-square-fill"></i>
    </div>
);

function PriceTable() {
    const [plans, setPlans] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // const [totalSlides, setTotalSlides] = useState(0); // Track total slides
    const [arrowDisabled, setArrowDisabled] = useState({ prev: false, next: false });
    const sliderRef = useRef<Slider | null>(null);

    const fetchData = async () => {
        const BackendUrl = process.env.REACT_APP_BACKEND_URL;
        try {
            const response = await fetch(BackendUrl + "webProduct");
            if (response.ok) {
                const result = await response.json();
                setPlans(result.Payload);
                setLoading(false);
            }
        } catch (error) {
            console.error("Failed to fetch data", error);
        }
    };

    const handleButtonClick = () => {
        window.location.href = process.env.REACT_APP_PORTAL_URL || "";
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const afterChange = (current: number) => {
        if (sliderRef.current) {
            // const slick = (sliderRef.current as any).innerSlider;
            const totalSlides = plans.length;
    
            // Function to get the effective slidesToShow based on the viewport width
            const getSlidesToShow = () => {
                const viewportWidth = window.outerWidth; // Get current viewport width
            
                // Log the viewport width for debugging
                console.log(`Viewport Width: ${viewportWidth}`);

                switch(true){
                    case viewportWidth >= 1234 && viewportWidth <= 1440 :
                        const showSlide = 4;
                        const isPrevDisabled = current === 0;
                        const isNextDisabled = (current+1) > totalSlides - showSlide;
                        setArrowDisabled({
                            prev: isPrevDisabled,
                            next: isNextDisabled
                        });
                        break;
                    case viewportWidth >= 946 && viewportWidth <= 1234 :
                        const showSlideOne = 3;
                        const isPrevDisabledOne = current === 0;
                        const isNextDisabledOne = (current+1) > totalSlides - showSlideOne;
                        setArrowDisabled({
                            prev: isPrevDisabledOne,
                            next: isNextDisabledOne
                        });
                       break;  
                    case viewportWidth >= 768 && viewportWidth <= 946 :
                            const showSlideTwo = 2;
                            const isPrevDisabledTwo = current === 0;
                            const isNextDisabledTwo = (current+1) > totalSlides - showSlideTwo;
                            setArrowDisabled({
                                prev: isPrevDisabledTwo,
                                next: isNextDisabledTwo
                            });    
                    break;
                    case viewportWidth < 768 :
                            const showSlideThree = 1;
                            const isPrevDisabledThree = current === 0;
                            const isNextDisabledThree = (current+1) > totalSlides - showSlideThree;
                            setArrowDisabled({
                                prev: isPrevDisabledThree,
                                next: isNextDisabledThree
                            });    
                    break;
                                
                }
            
            };
            
             const slidesToShow = getSlidesToShow();
            console.log(`Slides to Show afterChange: ${slidesToShow}`);
            
        }
    };
    
    

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow disabled={arrowDisabled.prev} />,
        nextArrow: <CustomNextArrow disabled={arrowDisabled.next} />,
        afterChange,

        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1234,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 946,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
    };

    return (
        <div>
            <Suspense fallback={null}>
                <Header />
            </Suspense>
            <section itemScope itemType="https://schema.org/Product" id="pricetable">
                <h1 className="text-center price-head">Plans For Any Kind Of Workflow</h1>
                <p className="text-center mt-3 price-para">
                    Signup now. Upgrade Anytime with a 15-day free trial
                </p>
                <div className="container">
                    {loading ? (
                        <div className="col d-flex justify-content-center">
                            <div className="loader mx-auto"></div>
                        </div>
                    ) : (
                        <Slider {...sliderSettings} ref={sliderRef}>
                            {plans.map((plan, index) => (
                                <div itemProp="offers" itemScope itemType="https://schema.org/Offer" className="card" key={index}>
                                    <div className={`${index % 2 === 0 ? "priceLight" : "priceDark"}`}>
                                        {plan.productName === "ESSENTIAL" && (
                                            <div className="special-offer">
                                                <p>POPULAR</p>
                                            </div>
                                        )}
                                        <h2 itemProp="name" className="text-2xl">{plan.productName}</h2>
                                        {plan.price !== 999 ? (
                                            <h3 className="text-3xl font-bold" itemProp="price">
                                                <i className="bi bi-currency-rupee"></i>
                                                {plan.price}
                                                <sub className="text-xl">/month</sub>
                                            </h3>
                                        ) : (
                                            <p className="text-xl pt-4">
                                                <span>Contact for Custom Quote</span>
                                            </p>
                                        )}
                                        <div className="card-header">
                                            <p itemProp="description" className="fw-bold">{plan.description}</p>
                                        </div>
                                        <div className="line mx-auto"></div>
                                        <div className="pricing-table">
                                            <ul className="pricing-table-list">
                                                {[plan.pricing_data_one, plan.pricing_data_two, plan.pricing_data_three, plan.pricing_data_four, plan.pricing_data_five].map((data, i) => (
                                                    data !== "default" && (
                                                        <li className="aval" key={i}>
                                                            <div className="d-flex pe-2"><i className="bi bi-check-circle-fill"></i><span>{data}</span></div>
                                                            <div>
                                                                <i className="bi bi-info-circle tooltip">
                                                                    <span className="tooltiptext">Please add the following dummy text in one of the tooltips when you get a chance:
```Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non nunc ut metus pharetra commodo pulvinar vel orci. Cras enim nulla, dictum sed purus a, vulputate molestie</span>
                                                                </i>
                                                            </div>
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                        <button className="select" onClick={handleButtonClick}>
                                            Choose this plan
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </section>
            <div className="container-fluid d-flex acc-content">
                <Suspense fallback={<div>Loading...</div>}>
                    <AccordingComp />
                </Suspense>
            </div>
            <Suspense fallback={null}>
                <Footer />
            </Suspense>
        </div>
    );
}

export default PriceTable;
