import Header from '../components/Header';
import Footer from '../components/Footer'; 
import {useEffect}  from "react";

const TermsComponent = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div>
            <Header />
            <div id="terms" className="max-w-3xl m-6 mx-auto py-8 terms-service">
                <div className="container privacy-content"> 
                <h1 className="text-orange font-bold mb-4 text-primary">Terms of Service</h1>
                <p className="mb-4 service-para">By using our software-as-a-service (SaaS) platform and related services (the "Service"), you agree to the
                following Terms of Service. Please read these Terms carefully before using the Service.</p> 
                <h2 className="head-font mb-2">ACCEPTANCE OF TERMS</h2> 
                <p className="service-para mb-4">By using the Service, you agree to be bound by these Terms and any additional terms referenced herein. If you do not
                    agree to these Terms, please do not use the Service.</p> 
                    <h2 className="head-font mb-2">USE OF SERVICE</h2> 
                    <p className="service-para mb-4">You may use the Service only in accordance with these Terms and applicable laws. You are responsible for maintaining
                        the security of your account credentials and for all activities that occur under your account.</p> 
                        <h2 className="head-font mb-2">PAYMENT AND FEES</h2>
                         <p className="service-para mb-4">Access to certain features of the Service may require payment of fees. By using these features, you agree to pay
                            the specified fees. All fees are non-refundable unless otherwise expressly stated.</p>
                   <h2 className="head-font mb-2">CHANGES TO TERMS</h2> 
                   <p className="service-para mb-4">We reserve the right to update or modify these Terms at any time. We will notify you of any material changes by
                                posting the updated Terms on our website or through other appropriate means. Your continued use of the Service
                                after such changes constitutes acceptance of the updated Terms.</p>
                                 <h2 className="head-font mb-2">GOVERNING LAW</h2> 
                                 <p className="service-para mb-4">These Terms shall be governed by and construed in accordance with the laws of Madhya Pradesh,
                                    India.</p> 
                                    <h2 className="head-font mb-2">CONTACT INFORMATION</h2>
                                     <p className='service-para'>If you have any questions about these Terms, please contact us at
                    <a href="mailto:info@receptionmonk.com" className="text-blue-500"> info@receptionmonk.com</a>.</p></div>
                  </div>  
            <Footer />
        </div>
    );
};export default TermsComponent;