import {useState,useEffect,useContext} from "react";
// @ts-ignore
import logo from "../assets/images/logo_rm.svg"
import { AppContext } from "../appcontext"
import { Link,useLocation} from "react-router-dom";



function Header() {
    const location = useLocation(); // Get current location object from React Router
  const { pathname } = location; // Extract pathname from location object
    const globalstate = useContext(AppContext) || { state: {},dispatch: () => {} };
    const {dispatch } = globalstate;
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
      window.addEventListener('scroll', handleScroll);
        return () => {  window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
      };
    const setGlobalState = (name:string,data:any) => {
        dispatch({ type: name, payload: data })
    }  
    const navigateToApp = () => {
        //window.location.href=process.env.REACT_APP_FRONTEND_URL||""
        window.location.href=process.env.REACT_APP_PORTAL_URL||""
        console.log(process.env.REACT_APP_PORTAL_URL)
      };
    return (<div className={`navbar nav-section pt-0 pb-0 ${scrolled ? 'scrolled' : ''}`}>
            <nav
                className={`navbar navbar-expand-lg fixed-top ${scrolled ? 'scrolled' : ''}`}>
           <a className="navbar-brand" href="/">   <img src={logo} title="app-logo" height={"120px"} width={"210px"} alt="logo"/> </a>                      
                    <button className="navbar-toggler" type="button" onClick={toggleNavbar}><span className="navbar-toggler-icon"></span></button>     
                    <div className={`collapse navbar-collapse ${collapsed ? '' : 'show'}`}  id="navbarCollapse">
                        <ul className="navbar-nav navbar-center">
                        <li className="nav-item"><Link to="/home" onClick={() => { setGlobalState("SET_GLOBAL", "")}} className={`nav-link ${pathname === '/home' ? 'active' : ''}`} data-scroll>Home</Link> </li>
                    <li className="nav-item"><Link to="/portal/about" onClick={() => { setGlobalState("SET_GLOBAL", "")}} className={`nav-link ${pathname === '/portal/about' ? 'active' : ''}`} data-scroll>About</Link></li>
                    <li className="nav-item"> <Link to="/portal/technology"  data-scroll className={`nav-link ${pathname === '/portal/technology' ? 'active' : ''}`}>Technology</Link> </li>
                    <li className="nav-item"> <Link  to="/pricing"  data-scroll onClick={() => {setGlobalState("SET_GLOBAL", "price")}} className={`nav-link ${pathname === '/pricing' ? 'active' : ''}`}>Pricing</Link></li>
                 <li className="nav-item"> <Link to="/portal/contact" data-scroll className={`nav-link ${pathname === '/portal/contact' ? 'active' : ''}`}>Contact</Link></li>
                <li className="nav-button p-2 ms-lg-0 m--7 mr-1"> <button className="btn btn-primary" onClick={navigateToApp}>Portal</button></li>
                        </ul>
                    </div>                        
            </nav>
        </div>
    );
}export default Header;