import React, {Suspense, useEffect} from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
const ContentOne = React.lazy(() => import('../components/BlockContentOne'));
const ContentTwo = React.lazy(() => import('../components/BlockContentTwo'));
const ContentThree = React.lazy(() => import('../components/BlockContentThree'));
const LazyLodedFooter = React.lazy(() => import('../components/Footer'));
function HomePage() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return (
        <div>

          <div className="bg-img">   
            <div id="home">
                     <Header />     
                        <Hero /> 
                        <Suspense fallback={null}>
                        <ContentThree />
                        <ContentOne />
                        <ContentTwo />
                        <LazyLodedFooter />
                          </Suspense> 
                </div>   
            </div>              
         </div>  
    );}export default HomePage;